<template>
  <div class="container sub-page-container">
    <div class="title-holder">
      <img v-if="pageId == 235" src="../assets/competition_icon.png" :alt="contents.page.title"/>
      <img v-else-if="pageId == 236" src="../assets/timing_icon.png" :alt="contents.page.title"/>
      <img v-else-if="pageId == 237" src="../assets/participants_icon.png" :alt="contents.page.title"/>
      <img v-else-if="pageId == 238" src="../assets/results_icon.png" :alt="contents.page.title"/>
      <h1>{{contents.page.title}}</h1>
    </div>
    <Results v-if="pageId == 238" :globalData="globalData" :pageId="pageId"></Results>
    <Participants v-else-if="pageId == 237" :globalData="globalData" :pageId="pageId"></Participants>
    <div v-else>
      <div v-html="mainContent"></div>
    </div>
  </div>
</template>

<script>
import Participants from './Participants.vue'
import Results from './Results.vue'

export default {
  name: 'SubPage',
  props:['globalData','pageId'],
  components: {
    Participants, Results
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head
    let mainContent = contents.page.mainContent
    if(isLocalhost==true && mainContent!==undefined){
      // mainContent = mainContent.replaceAll('/app','')
    }

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    return{
      mainContent,
      contents,
      language
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.mainContent = this.contents.page.mainContent
      if(isLocalhost==true && this.mainContent!==undefined){
        this.mainContent = this.mainContent.replaceAll('/app','')
      }
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    language(newValue){
      this.contents = this.globalData.contents[newValue][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  }
}
</script>
<style>
.sub-page-container{
  padding: 0 5.71rem;
  margin-bottom: 5rem;
}
.title-holder{
    padding-top: 4rem;
    margin-bottom: 2.571rem;
    display: flex;
    align-items: center;
    gap: 16px;
}
.title-holder h1{
  color: #52ACC4;
  font-size: 2.857rem;
  line-height: 3.428rem;
}
.sub-page-container h2{
  color: #52ACC4;
  font-size: 2.285rem;
  margin-bottom: 4rem;
}
.sub-page-container h3{
  color: #52ACC4;
  font-size: 1.714rem;
  font-weight: bold;
  margin-bottom: 3.428rem;
}
.sub-page-container a{
  color: #52ACC4;
  text-decoration: none;
  font-weight: bolder;
}
.sub-page-container :not(.title-holder) img{
  width: 100%;
}
.sub-page-container p{
  margin-bottom: 2.85rem;
}
/* .sub-page-container strong{
  font-size:1.285rem;
  line-height: 1.857rem;
} */
.sub-page-container figcaption{
  padding:20px 27px;
  background-color: rgba(216,216,216,.3);
  font-size: .857rem;
  margin-bottom: 3rem;
}
.period{
    margin-bottom: 2.285rem;
}
.period h2{
    color: #4C5359;
    font-weight: bold;
    font-size:1.285rem;
    line-height: 1.857rem;
    margin-bottom: .285rem;
}
.period p{
  margin-bottom: initial;
}
.period .blue-date{
    color:#52ACC4;
}
.period a.blue-date{
  font-weight: normal;
    text-decoration: none;
    position: relative;
}
.period a.blue-date::after{
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #52ACC4;
    position: absolute;
    top: 5px;
    right: -15px;
}
.period .table-responsive{
    width: fit-content;
}
.period .table{
    color:#4C5359;
}
.period thead{
    border-bottom: 1px solid #1C9FD4;
    padding-bottom: .571rem;
}
.period thead th{
    padding-right: 3.428rem;
    padding-left: 0;
    font-weight: 600;
}
.period td{
    padding-left: 0;
}

@media (max-width: 767px){
  .sub-page-container{
    padding: 1rem;
  }
}
</style>

<template>
    <div class="table-responsive">
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Csapatnév</th>
                    <th>Egyetem</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="participant in participantsData" :key="participant.teamName">
                  <td>{{participant.sort}}</td>
                  <td>{{participant.teamName}}</td>
                  <td>{{participant.university}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'Participants',
  props:['globalData','pageId'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    
    this.fetchData()

    return{
      participantsData:null
    }
  },
  watch:{
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  },
  methods:{
      fetchData() {
      this.globalData.loader.status = true;    
      this.$axios.get('/api/v1/participants')
          .then((response) => {
              this.participantsData = response.data.data
          })
          .catch(error => {
              console.log(error.message)
              console.error('There was an error!', error)
          })
          .then(() => {
                this.globalData.loader.status = false;
          });
    }
  }
}
</script>
<style scoped>
.table{
    color:#4C5359;
}
thead{
    border-bottom: 1px solid #1C9FD4;
    padding-bottom: .571rem;
}
thead th{
    font-weight: 600;
}
td{
    padding:15px 12px 19px 12px;
    border-top: 1px solid #52ACC4;
}
tbody tr:hover{
    background-color: #52ACC4;
    color: #FFFFFF;
    font-weight: bold;
}
th:first-child, td:first-child{
    text-align: center;
}
</style>
